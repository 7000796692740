import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAccount } from 'contexts/account-context';
import { AdminProvider } from 'contexts/admin-context';
import { useApp } from 'contexts/app-context';
import { BountyContext, BountyProvider } from 'contexts/bounty-context';
import { CollectionProvider } from 'contexts/collection-context';
import { DiscussionProvider } from 'contexts/discussion-context';
import { RepoContext, RepoProvider } from 'contexts/repo-context';
import { SubmitBountyProvider } from 'contexts/submit-bounty-context';
import { SubmitCollectionProvider } from 'contexts/submit-collection-context';
import { SubmitRepoProvider } from 'contexts/submit-repo-context';
import { TeamProvider } from 'contexts/team-context';
import { UserProvider } from 'contexts/user-context';
import * as logger from 'utils/logger';

export const PageContext = React.createContext(null);

const PageProvider = ({ ssr, children }) => {
	const { session, store, toast } = useApp();
	const account = useAccount();
	const router = useRouter();
	const contexts = { ssr, account, router, session, store, toast, children };

	// Hooks
	useEffect(() => {
		window.scrollTo(0, 0);
		logger.log('pathname: ', router.asPath);
	}, [router.pathname]);

	// Render
	if (router.pathname.includes('/admin')) {
		return (
			<AdminProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</AdminProvider>
		);
	}
	if (router.pathname.includes('/bounties/[bountyId]')) {
		if (router.pathname.includes('/[discussionId]')) {
			return (
				<BountyProvider {...contexts}>
					<BountyContext.Consumer>
						{context => (
							<DiscussionProvider type='Bounty' entity={context} {...contexts}>
								<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
							</DiscussionProvider>
						)}
					</BountyContext.Consumer>
				</BountyProvider>
			);
		}
		return (
			<BountyProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</BountyProvider>
		);
	}
	if (
		router.pathname.includes('/collections/[collectionId]') &&
		!router.pathname.includes('[teamId]') &&
		!router.pathname.includes('[uuid]')
	) {
		return (
			<CollectionProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</CollectionProvider>
		);
	}
	if (router.pathname.includes('/repos/[repoId]')) {
		if (router.pathname.includes('/[discussionId]')) {
			return (
				<RepoProvider {...contexts}>
					<RepoContext.Consumer>
						{context => (
							<DiscussionProvider type='Repo' entity={context} {...contexts}>
								<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
							</DiscussionProvider>
						)}
					</RepoContext.Consumer>
				</RepoProvider>
			);
		}
		return (
			<RepoProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</RepoProvider>
		);
	}
	if (router.pathname.includes('/submit/bounty/[bountyId]')) {
		return (
			<SubmitBountyProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</SubmitBountyProvider>
		);
	}
	if (router.pathname.includes('/submit/collection/[collectionId]')) {
		return (
			<SubmitCollectionProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</SubmitCollectionProvider>
		);
	}
	if (router.pathname.includes('/submit/repo/[repoId]')) {
		return (
			<SubmitRepoProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</SubmitRepoProvider>
		);
	}
	if (router.pathname.includes('/teams/[teamId]')) {
		if (router.pathname.includes('/[collectionId]')) {
			return (
				<TeamProvider {...contexts}>
					<CollectionProvider {...contexts}>
						<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
					</CollectionProvider>
				</TeamProvider>
			);
		}
		return (
			<TeamProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</TeamProvider>
		);
	}
	if (router.pathname.includes('/[uuid]')) {
		if (router.pathname.includes('/[collectionId]')) {
			return (
				<UserProvider {...contexts}>
					<CollectionProvider {...contexts}>
						<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
					</CollectionProvider>
				</UserProvider>
			);
		}
		return (
			<UserProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</UserProvider>
		);
	}
	return <PageContext.Provider value={contexts}>{children}</PageContext.Provider>;
};

export { PageProvider };
