import React, { useEffect, useState } from 'react';

import { repoDetailParams } from 'api';
import * as logger from 'utils/logger';

type RepoContext = {
	repo: any;
	setRepo: any;
	isOwner: boolean;
	rootApi: string;
	rootLink: string;
	secureApi: string;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

export const RepoContext = React.createContext<RepoContext>(null);

const RepoProvider = ({ ssr, account, session, store, toast, children }) => {
	const [repo, setRepo] = useState(ssr.repo?.data || {});
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);

	// Computed
	const rootApi = `/repos/${repo.uuid}`;
	const rootLink = `/repos/${repo.uuid}`;
	const secureApi = `${account.rootApi}/repos/${repo.uuid}`;
	const isOwner = account.account?.id === repo.ownerId && account.type === repo.ownerType;

	// Hooks
	useEffect(() => {
		setLoaded(true);
		return () => onClear();
	}, []);

	useEffect(() => {
		if (loaded) setRepo(ssr.repo?.data || {});
	}, [ssr.repo]);

	useEffect(() => {
		if (repo.id) logger.debug('repoContext: ', repo);
	}, [repo]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await store.queryRecord({
				url: `/repos/${repo.uuid}`,
				params: repoDetailParams,
			});
			setRepo(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const onUpdate = data => {
		setRepo({ ...repo, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setRepo({});
		setLoading(false);
	};

	// Render
	return (
		<RepoContext.Provider
			value={{ repo, setRepo, isOwner, rootApi, rootLink, secureApi, loading, onUpdate, onRefresh }}>
			{children}
		</RepoContext.Provider>
	);
};

const useRepo = () => {
	return React.useContext(RepoContext);
};

export { RepoProvider, useRepo };
