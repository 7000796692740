import { isArray, isEmpty, isObject } from 'utils/helpers';
import { camelToDash, dashToCamel } from 'utils/transforms';

// Helpers
const formatLookup = type => {
	switch (type) {
		case 'dash':
			return camelToDash;
		case 'camelcase':
			return dashToCamel;
	}
};

// serialize
export const serializeArray = (data = [], attrs = []) => {
	const serializedData = [];
	data.map(v => serializedData.push(serializeAttrs({ data: v, attrs })));
	return serializedData;
};

export const serialize = (data = {}, attrs = []) => {
	const serializedData = {};
	Object.assign(serializedData, serializeAttrs({ data, attrs }));
	return serializedData;
};

export const serializeAttrs = ({ type = 'dash', data = {}, attrs = [] }) => {
	const formatter = formatLookup(type);
	const serializedData = {};

	Object.keys(data).map(key => {
		if (attrs.length === 0) return (serializedData[formatter(key)] = data[key]);
		if (isArray(data[key]) && isObject(data[key][0])) {
			const arrayData = data[key];
			const arrayAttrs = attrs.filter(v => v.split('.')[0] === key).map(v => v.split('.')[1]);
			const formattedAttrs = !isEmpty(arrayAttrs[0]) ? arrayAttrs : [];
			if (!attrs.includes(key) && isEmpty(arrayAttrs)) return;
			return (serializedData[formatter(key)] = arrayData.map(data => serializeAttrs({ data, attrs: formattedAttrs })));
		}
		if (isArray(data[key])) {
			if (isEmpty(data[key])) return;
			return (serializedData[formatter(key)] = data[key]);
		}
		if (isObject(data[key])) {
			const objectData = data[key];
			const objectAttrs = attrs.filter(v => v.split('.')[0] === key);
			const formattedAttrs = objectAttrs.filter(v => v.includes('.')).map(v => v.split('.')[1]);
			if (isEmpty(objectAttrs)) return;
			return (serializedData[camelToDash(key)] = serializeAttrs({ data: objectData, attrs: formattedAttrs }));
		}
		if (attrs.includes(key)) return (serializedData[formatter(key)] = data[key]);
	});

	return serializedData;
};

// normalize
export const normalizeArray = (data = [], included = [], meta = {}) => {
	const normalizedArray = { data: [], meta: {} };
	data.map(v => normalizedArray.data.push(normalizeAttrs(v, included)));
	normalizedArray.meta = normalizeMeta(meta);
	return normalizedArray;
};

export const normalize = (data, attrs = []) => {
	const normalizedData = { data: {} };
	Object.assign(normalizedData.data, normalizeAttrs(data, attrs));
	// log('normalizedData: ', normalizedData);
	return normalizedData;
};

export const normalizeMeta = meta => {
	const normalizedMeta = {};
	Object.keys(meta).map(key => {
		normalizedMeta[dashToCamel(key)] = parseInt(meta[key]);
	});
	return normalizedMeta;
};

export const normalizeAttrs = (data, attrs = []) => {
	const normalizedAttrs = {};
	Object.keys(data).map(key => {
		if (attrs.includes(key)) return;
		if (isArray(data[key])) {
			return (normalizedAttrs[dashToCamel(key)] = data[key].map(v => normalizeAttrs(v, attrs)));
		}
		if (isObject(data[key])) {
			return (normalizedAttrs[dashToCamel(key)] = normalizeAttrs(data[key], attrs));
		}
		return (normalizedAttrs[dashToCamel(key)] = data[key]);
	});
	return normalizedAttrs;
};

export const normalizeAttr = (data, key) => {
	return { [dashToCamel(key)]: data[key] };
};

// Docs
//
