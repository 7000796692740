import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { PageLoader } from '@playbooks/interface/loaders';
import * as logger from 'utils/logger';

type iAdmin = {
	admin: any;
	setAdmin: any;
	accordions: string[];
	setAccordions: any;
	rootApi: string;
	rootLink: string;
	onRefresh?: any;
	loaded: boolean;
};

const AdminContext = React.createContext<iAdmin>(null);

const AdminProvider = ({ session, store, toast, children }) => {
	const [admin, setAdmin] = useState<any>({});
	const [accordions, setAccordions] = useState(['marketplace', 'accounts', 'pipelines']);
	const [loaded, setLoaded] = useState(false);
	const router = useRouter();

	// Computed
	const rootApi = ``;
	const rootLink = `/admin`;

	// Hooks
	useEffect(() => {
		if (session.loaded) fetchData();
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		if (admin.id) logger.debug('adminContext: ', admin);
	}, [admin]);

	// Methods
	const fetchData = async () => {
		try {
			if (session.user.role !== 'admin') throw new Error(`Sorry, you're not authorized`);
			setAdmin(session.user);
		} catch (e) {
			toast.showError(e);
			router.push('/403');
		} finally {
			setLoaded(true);
		}
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setAdmin({});
		setLoaded(false);
	};

	// Render
	return (
		<AdminContext.Provider value={{ admin, setAdmin, accordions, setAccordions, rootApi, rootLink, onRefresh, loaded }}>
			{loaded ? children : <PageLoader />}
		</AdminContext.Provider>
	);
};

const useAdmin = () => {
	return React.useContext(AdminContext);
};

export { AdminProvider, useAdmin };
