import React, { useEffect, useState } from 'react';

import * as logger from 'utils/logger';

type UserContext = {
	user: any;
	setUser: any;
	isOwner: boolean;
	rootApi: string;
	rootLink: string;
	secureApi: string;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

const UserContext = React.createContext<UserContext>(null);

const UserProvider = ({ ssr, account, router, session, store, toast, children }) => {
	const [user, setUser] = useState(ssr.user?.data || {});
	const [loading, setLoading] = useState(false);
	const { query } = router;
	const { uuid } = query;

	// Computed
	const rootApi = `/users/${uuid}`;
	const rootLink = `/${user.uuid}`;
	const secureApi = account.rootApi;
	const isOwner = account.account?.uuid === user.uuid && account.type === 'User';

	// Hooks
	useEffect(() => {
		return () => onClear();
	}, []);

	useEffect(() => {
		setUser(ssr.user?.data || {});
	}, [ssr?.user]);

	useEffect(() => {
		if (user.id) logger.debug('userContext: ', user);
	}, [user]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await store.queryRecord({ url: rootApi });
			setUser(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const onUpdate = data => {
		setUser({ ...user, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setUser({});
		setLoading(false);
	};

	// Render
	return (
		<UserContext.Provider
			value={{ user, setUser, isOwner, rootApi, rootLink, secureApi, loading, onUpdate, onRefresh }}>
			{children}
		</UserContext.Provider>
	);
};

const useUser = () => {
	return React.useContext(UserContext);
};

export { UserProvider, useUser };
