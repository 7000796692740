import React, { useEffect, useState } from 'react';

import * as logger from 'utils/logger';

type TeamContext = {
	team: any;
	setTeam: any;
	isOwner: boolean;
	rootApi: string;
	rootLink: string;
	secureApi: string;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

export const TeamContext = React.createContext<TeamContext>(null);

const TeamProvider = ({ ssr, account, session, store, toast, children }) => {
	const [team, setTeam] = useState(ssr.team?.data || {});
	const [loading, setLoading] = useState(false);

	// Computed
	const rootApi = `/teams/${team.uuid}`;
	const rootLink = `/teams/${team.uuid}`;
	const secureApi = `${account.rootApi}/teams/${team.uuid}`;
	const isOwner = account.account?.uuid === team.uuid && account.type === 'Team';

	// Hooks
	useEffect(() => {
		return () => onClear();
	}, []);

	useEffect(() => {
		setTeam(ssr.team?.data || {});
	}, [ssr?.team]);

	useEffect(() => {
		if (team.id) logger.debug('teamContext: ', team);
	}, [team]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await store.queryRecord({
				url: rootApi,
				params: { include: ['setup'] },
			});
			setTeam(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const onUpdate = data => {
		setTeam({ ...team, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setTeam({});
		setLoading(false);
	};

	// Render
	return (
		<TeamContext.Provider
			value={{ team, setTeam, isOwner, rootApi, rootLink, secureApi, loading, onUpdate, onRefresh }}>
			{children}
		</TeamContext.Provider>
	);
};

const useTeam = () => {
	return React.useContext(TeamContext);
};

export { TeamProvider, useTeam };
