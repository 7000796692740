import React, { useEffect, useState } from 'react';

import { useSession } from 'contexts/session-context';
import { useStorage } from 'contexts/storage-context';
import { useStore } from 'contexts/store-context';
import { useToast } from 'contexts/toast-context';

export const AppContext = React.createContext(null);

const AppProvider = ({ children }) => {
	const [taxonomy, setTaxonomy] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const session = useSession();
	const storage = useStorage();
	const store = useStore();
	const toast = useToast();
	const contexts = { session, storage, store, toast, children };

	// Hooks
	useEffect(() => {
		fetchData();
	}, []);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await store.queryRecord({
				url: `/taxonomy`,
				params: { 'meta.totalRepos': 1, pageSize: 5, sortProp: 'name', sortValue: 'asc' },
			});
			setTaxonomy(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	// Render
	return <AppContext.Provider value={{ ...contexts, taxonomy, loading }}>{children}</AppContext.Provider>;
};

const useApp = () => {
	return React.useContext(AppContext);
};

export { AppProvider, useApp };

// We could switch render depending on route.
