import React, { useEffect, useState } from 'react';

import { useStorage } from 'contexts/storage-context';
import * as logger from 'utils/logger';

type iTheme = {
	preference: 'light' | 'dark' | 'system';
	setPreference: any;
	isDark: boolean;
	loaded: boolean;
};

const ThemeContext = React.createContext<iTheme>(null);

const ThemeProvider = ({ children }) => {
	const storage = useStorage();
	const [preference, setPreference] = useState('');
	const [theme, setTheme] = useState('');
	const [loaded, setLoaded] = useState(false);

	// Computed
	const isDark = theme === 'dark';

	// Hooks
	useEffect(() => {
		if (!storage.loaded) return;
		if (storage.storage.preference === 'system') {
			const systemPreference = window.matchMedia('(prefers-color-scheme:dark)').matches;
			setPreference(storage.storage.preference);
			changeTheme(systemPreference ? 'dark' : 'light');
		} else {
			setPreference(storage.storage.preference);
			changeTheme(storage.storage.preference);
		}
		setLoaded(true);
	}, [storage.loaded]);

	useEffect(() => {
		if (!loaded) return;
		changeThemeByPreference(preference);
		storage.storeValue('preference', preference);
		storage.storeCookie('preference', preference);
	}, [preference]);

	useEffect(() => {
		logger.debug('theme:', { preference, theme });
	}, [preference, theme]);

	// Methods
	const changeThemeByPreference = preference => {
		if (preference === 'system') {
			const systemPreference = window.matchMedia('(prefers-color-scheme:dark)').matches;
			changeTheme(systemPreference ? 'dark' : 'light');
		} else {
			changeTheme(preference);
		}
	};

	const changeTheme = theme => {
		storage.storeValue('theme', theme);
		storage.storeCookie('theme', theme);
		theme === 'dark' ? document.body.classList.add('dark') : document.body.classList.remove('dark');
		setTheme(theme);
	};

	return (
		<ThemeContext.Provider value={{ preference, setPreference, isDark, loaded }}>{children}</ThemeContext.Provider>
	);
};

const useTheme = () => {
	return React.useContext(ThemeContext);
};

export { ThemeProvider, useTheme };
