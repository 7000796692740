import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const SearchContext = React.createContext(null);

const SearchProvider = ({ children }) => {
	const [showFilters, setShowFilters] = useState(true);
	const [showMobileFilters, setShowMobileFilters] = useState(false);
	const router = useRouter();

	// Hooks
	useEffect(() => {}, []);

	// Methods

	// Render
	return (
		<SearchContext.Provider value={{ showFilters, setShowFilters, showMobileFilters, setShowMobileFilters }}>
			{children}
		</SearchContext.Provider>
	);
};

const useSearch = () => {
	return React.useContext(SearchContext);
};

export { SearchProvider, useSearch };

// We could switch render depending on route.
