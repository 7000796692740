import React, { useEffect, useMemo, useState } from 'react';

import { commentParams, discussionDetailParams } from 'api';
import * as logger from 'utils/logger';

type DiscussionContext = {
	discussion: any;
	comments: any;
	params: any;
	setParams: (v) => any;
	meta: any;
	isOwner: boolean;
	rootApi: string;
	rootLink: string;
	fetchData: () => void;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

const DiscussionContext = React.createContext<DiscussionContext>(null);

const DiscussionProvider = ({ ssr, account, type, entity, router, session, store, toast, children }) => {
	const [discussion, setDiscussion] = useState(ssr.discussion?.data || {});
	const [comments, setComments] = useState(ssr.comments?.data || []);
	const [params, setParams] = useState(commentParams);
	const [meta, setMeta] = useState(ssr.comments?.meta || {});
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const { discussionId } = router.query;

	// Computed
	const rootApi = `${entity.rootApi}/discussions/${discussionId}`;
	const rootLink = `${entity.rootLink}/discussions/${discussionId}`;
	const isOwner = account.account?.id === discussion.ownerId && account.type === discussion.ownerType;

	const computedParams = useMemo(() => {
		const params = {};
		Object.keys(router.query)
			.filter(key => ['view'].includes(key))
			.map(key => (params[key] = router.query[key]));
		return params;
	}, [router.query]);

	// Hooks
	useEffect(() => {
		setLoaded(true);
		return () => onClear();
	}, []);

	useEffect(() => {
		if (loaded) setDiscussion(ssr.discussion?.data || {});
	}, [ssr.discussion]);

	useEffect(() => {
		if (discussion.id) logger.debug('discussionContext: ', discussion);
	}, [discussion]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			await fetchDiscussion();
			await fetchComments();
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const fetchDiscussion = async () => {
		const response = await store.queryRecord({
			url: rootApi,
			params: discussionDetailParams,
		});
		setDiscussion(response.data);
	};

	const fetchComments = async () => {
		const response = await store.query({
			url: `${rootApi}/comments`,
			params: { ...params, ...computedParams, level: 1 },
		});
		setComments(response.data);
		setMeta(response.meta);
	};

	const onUpdate = data => {
		setDiscussion({ ...discussion, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setDiscussion({});
		setLoading(false);
	};

	// Render
	return (
		<DiscussionContext.Provider
			value={{
				discussion,
				comments,
				params,
				setParams,
				meta,
				isOwner,
				rootApi,
				rootLink,
				fetchData,
				loading,
				onUpdate,
				onRefresh,
			}}>
			{children}
		</DiscussionContext.Provider>
	);
};

const useDiscussion = () => {
	return React.useContext(DiscussionContext);
};

export { DiscussionProvider, useDiscussion };
