import React, { useEffect, useState } from 'react';

import { bountyDetailParams } from 'api';
import * as logger from 'utils/logger';

type BountyContext = {
	bounty: any;
	setBounty: any;
	isOwner: boolean;
	rootApi: string;
	rootLink: string;
	secureApi: string;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

export const BountyContext = React.createContext<BountyContext>(null);

const BountyProvider = ({ ssr, account, session, store, toast, children }) => {
	const [bounty, setBounty] = useState(ssr.bounty?.data || {});
	const [loading, setLoading] = useState(false);

	// Computed
	const rootApi = `/bounties/${bounty.uuid}`;
	const rootLink = `/bounties/${bounty.uuid}`;
	const secureApi = `${account.rootApi}/bounties/${bounty.uuid}`;
	const isOwner = account.account?.id === bounty.ownerId && account.type === bounty.ownerType;

	// Hooks
	useEffect(() => {
		return () => onClear();
	}, []);

	useEffect(() => {
		setBounty(ssr.bounty?.data || {});
	}, [ssr?.bounty]);

	useEffect(() => {
		if (bounty.id) logger.debug('bountyContext: ', bounty);
	}, [bounty]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await store.queryRecord({
				url: `/bounties/${bounty.uuid}`,
				params: bountyDetailParams,
			});
			setBounty(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const onUpdate = data => {
		setBounty({ ...bounty, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setBounty({});
		setLoading(false);
	};

	// Render
	return (
		<BountyContext.Provider
			value={{ bounty, setBounty, isOwner, rootApi, rootLink, secureApi, loading, onUpdate, onRefresh }}>
			{children}
		</BountyContext.Provider>
	);
};

const useBounty = () => {
	return React.useContext(BountyContext);
};

export { BountyProvider, useBounty };
