import React, { useEffect, useState } from 'react';

import { PageLoader } from '@playbooks/interface/loaders';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type SubmitRepoContext = {
	repo: any;
	setRepo: any;
	rootApi: string;
	view: string;
	onView: any;
};

export const SubmitRepoContext = React.createContext<SubmitRepoContext>(null);

const SubmitRepoProvider = ({ account, router, session, store, toast, children }) => {
	const [repo, setRepo] = useState<any>({});
	const [loading, setLoading] = useState(true);
	const { repoId, view = 'welcome' } = router.query;

	// Computed
	const rootApi = `${account.rootApi}/repos/${repoId}`;

	// Hooks
	useEffect(() => {
		if (session.isAuthenticated()) fetchData();
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		logger.debug('repoContext: ', repo);
	}, [repo]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			await timeout(1000);
			const response = await store.queryRecord({
				url: rootApi,
				params: {
					include: [
						'demo',
						'framework',
						'language',
						'license',
						'medias',
						'platform',
						'quality',
						'setup',
						'tools',
						'topics',
					],
				},
			});
			setRepo(response.data);
		} catch (e) {
			toast.showError(e);
			router.push('/403');
		} finally {
			setLoading(false);
		}
	};

	const onView = view => {
		router.push(`/submit/repo/${repo.uuid}?view=${view}`);
		window.scrollTo(0, 0);
	};

	const onClear = () => {
		setRepo({});
	};

	// Render
	return (
		<SubmitRepoContext.Provider value={{ repo, setRepo, rootApi, view, onView }}>
			{loading ? <PageLoader message='Loading your workflow...' /> : children}
		</SubmitRepoContext.Provider>
	);
};

const useSubmitRepo = () => {
	return React.useContext(SubmitRepoContext);
};

export { SubmitRepoProvider, useSubmitRepo };
