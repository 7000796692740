import React, { useEffect, useState } from 'react';

import { PageLoader } from '@playbooks/interface/loaders';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type SubmitBountyContext = {
	bounty: any;
	setBounty: any;
	rootApi: string;
	view: string;
	onView: any;
};

export const SubmitBountyContext = React.createContext<SubmitBountyContext>(null);

const SubmitBountyProvider = ({ account, router, session, store, toast, children }) => {
	const [bounty, setBounty] = useState<any>({});
	const [loading, setLoading] = useState(true);
	const { bountyId, view = 'welcome' } = router.query;

	// Computed
	const rootApi = `${account.rootApi}/bounties/${bountyId}`;

	// Hooks
	useEffect(() => {
		if (session.isAuthenticated()) fetchData();
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		logger.debug('bountyContext: ', bounty);
	}, [bounty]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			await timeout(1000);
			const response = await store.queryRecord({
				url: rootApi,
				params: {
					include: ['framework', 'language', 'license', 'platform', 'setup', 'tools', 'topics'],
				},
			});
			setBounty(response.data);
		} catch (e) {
			toast.showError(e);
			router.push('/403');
		} finally {
			setLoading(false);
		}
	};

	const onView = view => {
		router.push(`/submit/bounty/${bounty.uuid}?view=${view}`);
		window.scrollTo(0, 0);
	};

	const onClear = () => {
		setBounty({});
	};

	// Render
	return (
		<SubmitBountyContext.Provider value={{ bounty, setBounty, rootApi, view, onView }}>
			{loading ? <PageLoader message='Loading your workflow...' /> : children}
		</SubmitBountyContext.Provider>
	);
};

const useSubmitBounty = () => {
	return React.useContext(SubmitBountyContext);
};

export { SubmitBountyProvider, useSubmitBounty };
